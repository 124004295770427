import { getRequest, postRequest } from './index'
import { APISRCUL, SRCURL } from '@/config'

// 加工服务分页列表查询
export const listPageFactoryBusiness = (data, successCallback, failureCallback) => {
  postRequest(`${APISRCUL}/factory/listPageFactoryBusiness`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 加工服务详情查询
export const queryFactoryBusinessDesc = (id, successCallback, failureCallback) => {
  getRequest(`${APISRCUL}/factory/queryFactoryBusinessDesc/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 加工单-委托方-保存加工单
export const saveFactoryOrder = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/trade/factory/order/saveFactoryOrder`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
